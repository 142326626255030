import React from 'react'

function Home() {
  return (
    <div style={{display:"flex", 
    justifyContent:'center',
     alignItems:'center', 
     textAlign:'center',
     height:"100vh",
     fontStyle:'sans-serif',
     background:"#e3e1d9",
     fontSize:"35px",
     flexDirection:'column'
    }}>
       <p>Coming Soon...</p> 
       <h3>Contact: 9661362218</h3>
       <h5>Email: info@salmadinatours.com</h5>
        </div>
  )
}

export default Home