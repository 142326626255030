// import Navbar from "./components/navbar/Navbar";
import Home from "./containers/Home";
import { BrowserRouter } from "react-router-dom";
import Helmet from "react-helmet";

function App() {
  return (
    <>
    <BrowserRouter>
    <Helmet>
       <title>Swat Al Madina Tours and Travels</title>
      <meta name="description" content="Book your tours with Swat Al Madina Tours and Travels get the best prices."/>
              </Helmet>
    {/* <Navbar/> */}
     <div className="App">
  <Home/>
    </div>
    </BrowserRouter>
    </>
   
  );
}

export default App;
